<template>
  <div>
    <EditDialog
      width="42%"
      :isReturn="true"
      @closeFn="$emit('update:printDiaLog', false)"
      ref="IframeDlg"
      :isShow.sync="printDiaLog"
    >
      <div slot="content" class="content">
        <div id="printArea">
          <div>发票申请表</div>
          <div class="outer-table">
            <i>编号：{{ form.expenseNumber }}</i>
            <i>{{ newDate | completeTime }}</i>
          </div>
          <table border="1">
            <thead>
              <tr>
                <th>开票单位</th>
                <th colspan="5">
                  {{ form.companyType | dict(companyType) }}
                </th>
              </tr>
              <tr>
                <th>申请人</th>
                <th>
                  {{ form.createName }}
                </th>
                <th>申请日期</th>
                <th colspan="2">
                  {{ form.taskDate | dateFormat }}
                </th>
              </tr>
              <tr>
                <th>项目所属部门</th>
                <th>
                  {{ form.deptClassifyName }}
                </th>
                <th>分管高管</th>
                <th colspan="2">
                  {{ form.fzManagerName }}
                </th>
              </tr>
              <tr>
                <th>项目类型</th>
                <th colspan="5">
                  {{ form.projectSource | dict(projectSourceArr) }}
                </th>
              </tr>
              <tr>
                <th>项目名称</th>
                <th colspan="5" style="line-height: 17px">
                  {{ form.projectName }}
                </th>
              </tr>
              <tr>
                <th>合同名称</th>
                <th colspan="5" style="line-height: 17px">
                  {{ form.agreementName }}
                </th>
              </tr>
              <tr>
                <th>合同编号</th>
                <th colspan="5">
                  {{ form.agreementNum }}
                </th>
              </tr>
              <tr>
                <th>合同总金额</th>
                <th colspan="5">
                  {{ form.actualContractAmount | applyAmount }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>单位名称</td>
                <td colspan="5" style="line-height: 17px">
                  {{ form.customersUnit }}
                </td>
              </tr>
              <tr>
                <td>纳税人识别号</td>
                <td colspan="5">
                  {{ form.taxpayerNumber }}
                </td>
              </tr>
              <tr>
                <td>地址、电话</td>
                <td colspan="5" style="line-height: 17px">
                  {{ form.address }} 、 {{ form.phone }}
                </td>
              </tr>
              <tr>
                <td>开户行、账号</td>
                <td colspan="5" style="line-height: 17px">
                  {{ form.openingBank }} 、 {{ form.account }}
                </td>
              </tr>
              <tr>
                <td>开具发票类型</td>
                <td colspan="5">
                  {{ form.invoiceType | dict(invoiceType) }}
                </td>
              </tr>
              <tr>
                <td>税率</td>
                <td colspan="5">{{ form.vatRate }} %</td>
              </tr>
              <tr>
                <td>开票内容</td>
                <td colspan="5">
                  {{ form.vatData | transitionData(vatArr) }}
                </td>
              </tr>
              <tr>
                <td>发票备注</td>
                <td colspan="5">
                  {{ form.invoiceRemark }}
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  本项目开票共 {{ form.periods }} 期，本次申请开具第 {{ form.currentPeriods }} 期
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <table border="1">
            <tbody> -->
          <div class="table-thead">
            <span class="table-serial">序号</span>
            <span class="table-stageName">阶段名称</span>
            <span class="table-ratio">比例</span>
            <span>开票金额</span>
            <span>开票日期</span>
          </div>
          <!-- style="height: 40px" -->
          <div v-for="(item, index) in form.invoiceApplicationDetailList" :key="item.id">
            <template v-if="item.invoiceAmount">
              <span class="table-serial">{{ index + 1 }}</span>
              <span class="table-stageName">{{ item.stageName }}</span>
              <span class="table-ratio">
                {{
                  isFiniteFn(Number(((item.invoiceAmount / item.paymentAmount) * 100).toFixed(2)))
                }}
                %
              </span>
              <span>
                {{ item.invoiceAmount | applyAmount }}
              </span>
              <span colspan="2">
                {{ form.invoiceDate | dateFormat }}
              </span>
            </template>
            <template v-else>
              <p></p>
            </template>
          </div>
          <!-- </tbody>
          </table> -->
          <table border="1">
            <tfoot>
              <tr style="height: 33px">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td>分管高管审批</td>
                <td>{{ examineInfo.auditUserName }}</td>
                <td>批准日期</td>
                <td>{{ examineInfo.auditDate | dateFormat }}</td>
              </tr>
              <tr>
                <td>开票人</td>
                <td>{{ form.createName }}</td>
                <td>开票日期</td>
                <td>{{ form.invoiceDate | dateFormat }}</td>
              </tr>
              <tr>
                <td>发票号码</td>
                <td colspan="3">{{ form.invoicingNum }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" v-print="print">打印</el-button>
        <el-button type="info" @click="$emit('update:printDiaLog', false)">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    form: {
      type: Object,
      default: function () {
        return {}
      },
    },
    examineInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    printDiaLog: {
      type: Boolean,
      default: false,
    },
    vatArr: {
      type: Array,
      default: function () {
        return []
      },
    },
    projectSourceArr: {
      type: Array,
      default: function () {
        return []
      },
    },
    companyType: {
      type: Array,
      default: function () {
        return []
      },
    },
    invoiceType: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      print: {
        id: 'printArea',
        popTitle: '', // 打印配置页上方的标题
        type: 'html', //打印类型是html
        scanStyles: false,
        targetStyles: ['*'],
      },
      newDate: Date.now(),
    }
  },
  created() {},
  computed: {
    projectSourceSuoeriors() {
      let name = ''
      const production = ['yi_shou_zhi_qian', 'zi_zhi_he_zuo', 'fen_bao_zhuan_bao']
      const cooperation = ['zi_zhi_ti_gong', 'zheng_ti_wai_xie', 'shang_wu_ju_jian']
      if (production.indexOf(this.form.projectSource) != -1) {
        name = '生产项目'
      } else if (cooperation.indexOf(this.form.projectSource) != -1) {
        name = '合作项目'
      }
      return name
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val && val.invoiceApplicationDetailList) {
          let numLength = val.invoiceApplicationDetailList.length % 6
          while (numLength < 6 && numLength !== 0) {
            val.invoiceApplicationDetailList.push({
              invoiceAmount: null,
              stageName: null,
              paymentAmount: null,
              invoiceDate: null,
            })
            numLength = val.invoiceApplicationDetailList.length % 6
          }
          val.invoiceApplicationDetailList.forEach(v => {
            if (v.stageName && v.stageName.length > 18) {
              v.stageName = v.stageName.substring(0, 17)
            }
          })
        }
      },
    },
  },
  methods: {
    isFiniteFn(val) {
      if (isFinite(val)) {
        return val // 输出 Infinity
      } else {
        return 0 // 如果结果是 Infinity，则输出 0
      }
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>

<style lang="scss" scoped>
#printArea {
  word-break: break-all !important;
  font-family: '微软雅黑';
  width: 700px;
  text-align: center;
  .outer-table {
    display: flex;
    justify-content: space-between;
  }
  table {
    font-size: 12px !important;
    width: 700px;
    th,
    td {
      width: 24.5%;
      overflow-wrap: break-word;
    }
    tr,
    th,
    td {
      height: 33px;
    }
  }
  .table-thead {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  span {
    display: inline-block;
    // height: 60px;
    // width: 140px;
    width: 20%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #8d8d8d;
  }
  p {
    height: 40px;
    border: 1px solid #8d8d8d;
  }
  .table-serial {
    // width: 70px;
    width: 6%;
  }
  .table-ratio {
    // width: 70px;
    width: 14%;
    white-space: nowrap;
  }
  .table-stageName {
    width: 40%;
    // width: 280px;
  }
}
@media print {
  @page {
    margin: 8mm 10mm;
    size: portrait; //纵向
  }

  tbody {
    page-break-inside: avoid; /* 避免将tbody分割在多个页面上 */
  }
}
</style>
